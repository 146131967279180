<template>
  <div class="pages">
    <div class="page">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :highlight-current="true"
          accordion
          @node-click="handleNodeClick"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <!-- select 筛选 -->
        <div class="select_box"></div>
        <div class="oper_box">
          <div class="s_item s_item_s">
            <div class="s_lable">数据周期：</div>
            <el-select
              v-model="selectValue"
              placeholder="请选择数据周期"
              @change="changeTime"
            >
              <el-option
                v-for="item in numoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-option>
            </el-select>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">查询日期：</div>
            <el-date-picker
              v-model="screen"
              type="date"
              @change="timeChange(screen)"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="btns" @click="query">查询</div>
        </div>
        <div class="charts">
          <div class="chartsTitle">用电趋势</div>
          <div class="onecharts"></div>
        </div>
        <div class="charts">
          <div class="chartsTitle">变化率趋势</div>
          <div class="twocharts"></div>
        </div>
        <div class="Tips">
          <div class="oneTips">
            <div class="title">1、用电趋势</div>
            <div class="con">
              累计用电量：<span>{{ yongdian.total }}度</span>，环比<span
                >{{ yongdian.ringCompare }}%</span
              >，{{ yongdian.ringMsg
              }}<span>{{ yongdian.ringValue }}万</span>：同比<span>{{
                yongdian.sameCompare
              }}</span
              >，{{ yongdian.sameMsg }}<span>{{ yongdian.sameCompare }}</span>
            </div>
          </div>
          <div class="oneTips">
            <div class="title">2、变化趋势</div>
            <div class="con">
              最大变化率：<span>{{ bianhua.max }}%</span>，最小变化率：<span
                >{{ bianhua.min }}%</span
              >，平均变化率：<span>{{ bianhua.avg }}%</span
              >，根据变化趋势，预测下一周期总体同电量：<span>{{
                bianhua.expectedElectricity
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      numoptions: [
        {
          value: "days",
          label: "日",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "year",
          label: "年",
        },
      ],
      selectValue: "",
      // 参数
      cycle: "",
      organize_id: "",
      screen: "",
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      radio: 1,

      activeIndex: "1",
      indexs: 1,
      dynamicTags: ["标签一", "标签二", "标签三"],
      yongdian: {},
      bianhua: {},
    };
  },
  mounted: function () {
    // this.qiCharts();
    this.getzuzhiList();
  },
  methods: {
    // 用电趋势
    async getqushiList() {
      const res = await this.$http.post("device/accumulate_trend", {
        screen: this.screen,
        cycle: this.cycle,
        organize_id: this.organize_id,
      });
      this.yongdian = res.data.electric;
    },
    //变化趋势
    async getbianhuaList() {
      const res = await this.$http.post("device/changerate_trend", {
        screen: this.screen,
        cycle: this.cycle,
        organize_id: this.organize_id,
      });
      this.bianhua = res.data;
    },

    async query() {
      const res = await this.$http.post("device/electric_trend", {
        screen: this.screen,
        cycle: this.cycle,
        organize_id: this.organize_id,
      });
      if (res.code !== 1) return this.$message.error(res.msg);

      const restwo = await this.$http.post("device/changerate_trend", {
        screen: this.screen,
        cycle: this.cycle,
        organize_id: this.organize_id,
      });
      if (restwo.code !== 1) return this.$message.error(restwo.msg);
      // this.$message.success(res.msg)
      this.oneChart(res.data);
      this.twoChart(restwo.data);
      this.getqushiList();
      this.getbianhuaList();
    },
    // 周期下拉
    changeTime(e) {
      this.cycle = e;
    },
    // 时间选择
    timeChange(val) {
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.screen = newDate;
    },

    // 组织 获取
    async getzuzhiList() {
      const res = await this.$http.post("Organize");
      this.treeData = res.data;
    },
    handleNodeClick(data) {
      // 组织
      console.log(data);
      this.organize_id = data.id;
    },

    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // qiCharts() {
    //   this.$nextTick(() => {
    //     this.oneChart();
    //     this.twoChart();
    //   });
    // },
    oneChart(data) {
      var myChart = echarts.init(document.querySelector(".onecharts"));
      var oneOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data.date,
            axisLabel: {
              color: "#fff", // 文本颜色
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff", // 文本颜色
            },
            name: "Kw/h",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "30%",
            data: data.value,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                    // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                    offset: 0,
                    color: "#3271AD",
                  },
                  {
                    offset: 1,
                    color: "#3271AD",
                  },
                ]),
              },
            },
          },
        ],
      };
      myChart.setOption(oneOption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    twoChart(data) {
      var myChart = echarts.init(document.querySelector(".twocharts"));
      var twoOption = {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data.date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data.value,
            type: "line",
            smooth: true,
            color: ["#68D8FE"],
          },
        ],
      };
      myChart.setOption(twoOption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #61cdf1 !important;
  }
  // charts
  .onecharts {
    width: 100%;
    height: 300px;
  }
  .twocharts {
    width: 100%;
    height: 300px;
  }
  .chartsTitle {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
  .Tips {
    width: 1240px;
    height: 158px;
    background: url("../../assets/images/trendBg.png") no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    margin-left: 68px;
    margin-top: 10px;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .oneTips {
      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e5e5e5;
        margin-bottom: 10px;
      }
      .con {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e5e5e5;
        margin-bottom: 10px;
        text-indent: 36px;
        span {
          color: #68d8fe;
        }
      }
    }
  }
  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #61cdf1 !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }
  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      margin-left: 80px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
</style>
